import { Card } from "zudoku/ui/Card";
import { Checkbox } from "zudoku/ui/Checkbox";
import { Button } from "zudoku/ui/Button";
import { useSignedMutation } from "../useQuery";
import { useState } from "react";
import { useAuth } from "zudoku/components";

const TosPrompt = ({ onAccept }: { onAccept: () => void }) => {
  const auth = useAuth();
  const email =
    auth && auth.profile && auth.profile.email ? auth.profile.email : undefined;
  const developerMutation = useSignedMutation(
    `/v1/developer`,
    {
      method: "PATCH",
    },
    {
      onSuccess: () => onAccept(),
    }
  );
  const [isAccepted, setIsAccepted] = useState(false);

  return (
    <div className="w-full mt-12">
      <div className="m-auto prose dark:prose-invert">
        <div className="text-center">
          <h2 className="my-0 dark:text-white">Let’s get started</h2>
          <p className="m-0">
            Placeholder information if it’s needed, else I can hide this
          </p>
        </div>
        <Card className="max-h-80 overflow-scroll p-4 mt-4">
          <TosText />
        </Card>
        <label className="flex items-center font-semibold gap-2 my-4">
          <Checkbox
            checked={isAccepted}
            onCheckedChange={(checked) => setIsAccepted(!!checked)}
            name="tos"
            id="tos"
            required
          />{" "}
          Accept Terms & Conditions of Use
        </label>
        <Button
          className="w-full cursor-not-al"
          variant="secondary"
          disabled={!isAccepted}
          onClick={() => {
            developerMutation.mutate({
              data: {
                email,
                tosAccepted: isAccepted,
              },
            });
          }}
        >
          Continue
        </Button>
        <p className="text-muted-foreground">
          By clicking “Continue”, you signify that you have read, understood,
          and agree to AccuWeather’s Terms & Conditions of Use.
        </p>
      </div>
    </div>
  );
};

const TosText = () => {
  return (
    <div className="max-w-full">
      <h3 className="mt-0">AccuWeather Data Service Terms of Use</h3>
      <p>
        These Terms of Use are a legal agreement between You and Your
        organization or company (if applicable) (collectively “Developer” or
        “You”) and AccuWeather Intl., LLC (hereafter “AccuWeather” or “We”)
        relating to Your use of one or more of AccuWeather’s Applications
        Programming Interfaces (hereafter “APIs”) and the information contained
        in the APIs (the “API Data”) to provide additional functionality in
        connection with Developer’s product offerings which may include
        applications, products, services, programs or other uses produced or
        provided by You (“Developer Uses”). You represent that You have
        authority to enter into this agreement and by clicking “I accept” or
        otherwise accessing the APIs, that You accept these Terms of Use.
      </p>{" "}
      <p>
        If You are using the APIs on behalf of an entity, You represent and
        warrant that You have authority to bind that entity to the Terms of Use
        and by accepting the Terms, You are doing so on behalf of that entity
        (and all references to "You" in the Terms refer to that entity as well).
      </p>
      <p>
        You represent that You are at least eighteen (18) years of age (or the
        age of majority in Your jurisdiction) and have the capacity to create a
        binding legal obligation with regard to Your commitments in the Terms of
        Use.
      </p>
      <p>
        We have the right to update or change the Terms of Use at any time. The
        most current version of the Terms of Use will supersede and replace all
        prior versions. By continuing to use the APIs after modifications are
        made, You agree to be bound by any and all changes to the Terms of Use.
        If You choose not to agree, You must immediately discontinue Your use of
        the APIs. Provided that You fully comply with these Terms of Use and pay
        the applicable fees, You are granted the license below. YOU ACKNOWLEDGE
        THAT YOU HAVE READ AND UNDERSTOOD THESE TERMS OF USE. BY USING THE APIs
        OR THE API DATA, YOU AGREE TO BE BOUND BY THESE TERMS OF USE AND TO
        COMPLY WITH ALL APPLICABLE LAWS AND REGULATIONS IN CONNECTION WITH YOUR
        USE. IF YOU DO NOT AGREE, DO NOT USE THE APIs OR THE API DATA.
      </p>
      <ol>
        <li>
          <strong>Account and Registration. </strong>Developer shall provide
          AccuWeather with true and correct contact information and shall update
          such information as necessary to ensure that AccuWeather always has
          current information for the Developer in connection with Developer’s
          account. Developer consents to receiving phone calls, emails, texts or
          any other type of messages from AccuWeather relating to the account
          and for general marketing purposes. Once You have successfully
          registered and provided Your credit card information, You will be
          given an API license key(s) relating to the chosen APIs. All
          activities that occur related to Your account and API license key(s)
          are Your responsibility. In the event that You suspect that either may
          have been compromised, You shall notify AccuWeather immediately and
          terminate Your account. You are responsible for any and all activity
          in connection with Your API license key(s) and Your account. You agree
          to have complete and correct billing and credit card information on
          file at all times and to be responsible for all charges in relation to
          Your account. All queries requesting data from the APIs must reference
          Your valid login API license key(s). You agree to keep such account
          information and API license key(s) information confidential.
          <br />
          &nbsp;
        </li>
        <li>
          <strong>Subscription Terms.</strong> You agree to pay to AccuWeather
          the Package fee(s). Failure to pay these fees in a timely manner will
          result in an interruption of Your access to the APIs and the API Data.
          Charges will be calculated based upon the subscription Package
          selected, and are applicable to the current version of the package
          selected. You may cancel Your Package at any time. Failure of payment
          shall render due and payable all sums plus a late payment charge of
          one and one half percent per month. Fees may be prorated for portions
          of months. Use of the APIs and the API Data beyond the limits in Your
          chosen Package will result in charges to Your credit card account.
          Fees and payment terms therefore are subject to change, so please
          check these Terms of Use from time to time. Packages, once purchased,
          are nonrefundable, regardless of use or lack of use by You. Absolutely
          no credits or refunds are available. Once a charge has been processed
          to Your credit card or wireless account, You shall not request that
          Your credit card reverse the charge or charge it back to AccuWeather.
          If You have a legitimate basis to request a credit for a charge
          previously processed against Your credit card account, he or she shall
          request a credit from AccuWeather by written notice and resolve the
          issue directly with AccuWeather. Any reversed charges which cause the
          credit card company to impose a charge back, refund, or credit cost
          against AccuWeather shall be reimposed by AccuWeather upon You. Such
          costs may exceed the cost of the reversed item or charge back by many
          times.
          <br />
          &nbsp;
        </li>
        <li>
          <strong>Renewal Terms.</strong> Monthly subscription Packages shall
          continue to renew monthly until either party terminates. Each month,
          you will be billed for that calendar month at the start of that
          calendar month at midnight UTC time; however your charges may not
          appear until the second calendar day of the month.
          <br />
          &nbsp;
        </li>
        <li>
          <strong>Cancellation.</strong> Developer may cancel the subscription
          Package at any time. In order to cancel, log in to Your account and
          select “My Purchased Packages” from the user menu (available by
          clicking on Your username in the top right). Click the “Cancel” button
          beside the Package(s) that You would like to cancel. Click “Confirm.”
          Upon cancellation, Your API license key(s) will become inactive at
          midnight UTC on the date of cancellation. If you do not wish to be
          billed for next month’s service, you will need to cancel before the
          end of the current calendar month at midnight UTC time.
          <br />
          &nbsp;
        </li>
        <li>
          <strong>Responsibility for Developer Uses. </strong>Developer is
          solely responsible for Developer Uses and AccuWeather shall have no
          liability or obligations to You or to any third parties with respect
          to the same (including support obligations). Developer represents,
          warrants and covenants that Developer has and will at all times
          maintain the right to provide all of Developer Uses hereunder and the
          Developer Uses do not infringe the intellectual property or other
          rights of any third parties. Developer also represents, warrants and
          covenants that all of Developer Uses will not contain viruses, worms,
          malware or any other harmful scripts or code. AccuWeather reserves the
          right to suspend or terminate access to the APIs without prior notice
          in the event that You are suspected of violating the Terms of Use.
          <br />
          &nbsp;
        </li>
        <li>
          <strong>License. </strong>Subject to Your compliance with these Terms
          of Use (including but not limited to payment hereunder and the
          Conditions and Restrictions below), AccuWeather hereby grants to
          Developer a revocable, non-exclusive, non-transferable,
          non-sublicensable, limited license to use and integrate the APIs into
          Developer Uses (hereafter the “License”).
          <p>
            The License grant allows You to access, use and receive the API Data
            through the applicable APIs, and to transmit, use and display the
            API Data and the AccuWeather Mark(s), consistent with the
            Exclusions, Conditions and Restrictions below and subject to the
            Terms of Use.
          </p>
          <p>
            The API Data in each Package will be made available for worldwide
            locations. AccuWeather, as part of its standard API Data offerings
            can change location data from time to time (at AccuWeather’s sole
            option and for certain international locations). API Data will be
            updated in accordance with AccuWeather’s normal updates cycles which
            may vary between types of data and which may change from time to
            time. AccuWeather reserves the right to change or substitute the API
            Data included in the APIs from time to time and to adjust or modify
            the content, graphics or images contained therein.&nbsp;
          </p>
        </li>
        <li>
          <strong>Exclusions, Conditions and Restrictions of License. </strong>
          <p>
            Specifically excluded from the License grant relating to the APIs
            and the API Data are any television type uses such as electronic
            publishing, database transmissions, side band transmissions, cable
            castings, over-the-air transmission, nationally originated
            television, locally originated television, cable television,
            satellite television, internet protocol television, OTT, interactive
            television or other types of broadcasts, such as radio broadcast,
            internet broadcast, wireless broadcast, satellite or other broadcast
            technology which currently exists or which may exist in the future.
          </p>
          <p>
            Additional exclusions from the license grant include the use of the
            APIs or the API Data in connection with i.) the targeting of or
            triggering of advertising;&nbsp;ii.) any “connected car”
            applications, products or services or any vehicle telematics; iii.)
            the creation of an archive, database or other type of storage of
            information; and/or iv.) any dangerous, vital, life-threatening
            and/or mission critical application, product, service or facility.
            Examples of the types of uses that would be covered by this
            exclusion include but are not limited to such uses as for the
            operation of emergency services, nuclear facilities, air traffic
            control, and/or life support systems. You agree not to use the APIs,
            the API Data or the AccuWeather Mark for any purpose that is
            prohibited by law or in connection with any activity that may be
            unlawful or which would otherwise be considered to violate the
            rights of third parties.
          </p>
          <p>
            Developer agrees that the License does not allow and Developer will
            not:
          </p>
          <ol>
            <li>
              change, modify or otherwise alter the APIs or the API Data in any
              manner or modify their link structure;
            </li>
            <li>
              reverse engineer, decompile or otherwise attempt to extract the
              source code of the APIs or any part thereof, unless doing so is
              expressly permitted or required by applicable laws;
            </li>
            <li>
              allow direct or indirect access to the stand-alone APIs or resell,
              sublicense or redistribute all or any part of the APIs or the API
              Data to any third party;
            </li>
            <li>
              commingle or supplement API Data from the APIs with any other
              weather information;
            </li>
            <li>
              delete, remove, or otherwise modify any API Data or AccuWeather
              Mark that may or may not be included in the APIs, or in any way
              prevent them from appearing with the APIs as made available by
              AccuWeather;
            </li>
            <li>
              display API Data or the AccuWeather Mark(s) in any manner that
              suggests or implies an endorsement or sponsorship by AccuWeather
              or that a reasonable person could infer that content other than
              the API Data is approved by or is otherwise associated with
              AccuWeather;
            </li>
            <li>
              use the APIs or the API Data in connection with any activities or
              in any manner that could lead to death, personal injury, property
              damage or environmental damage;
            </li>
            <li>
              use the APIs or the API Data in a way that impacts the stability
              of the API servers, impacts the use of the APIs by others, or to
              interfere or disrupt products or services of AccuWeather;
            </li>
            <li>
              use the APIs or the API Data to disparage, rate, rank, review or
              otherwise evaluate AccuWeather or its services and products;
            </li>
            <li>
              use the APIs or the API Data in a manner that is not consistent
              with the{" "}
              <a href="#acceptableuse">AccuWeather Acceptable Use Policy</a> as
              exists from time to time.
            </li>
          </ol>
          <p>Developer agrees to the following conditions:</p>
          <p>
            Developer Use of the APIs and API Data shall not be used to target
            current or potential customers of AccuWeather or to offer products
            and services that may be similar to those offered by AccuWeather.
          </p>
          <p>
            Developer may cache the API Data for up to two (2) weeks for
            purposes of improving the user experience. There are no rights to
            store API Data for any other purpose.
          </p>
          <p>
            In all uses of the APIs and the API Data which are licensed
            hereunder, Developer shall attribute AccuWeather by name and using
            AccuWeather brand logo as the source of the API Data. Developer
            shall comply with the AccuWeather’s Logo Usage Guide located at{" "}
            <a
              className="sc-hdNmWC bTCtxA"
              href="https://accuweathercloud.sharepoint.com/:f:/s/AccuWeatherRecordings/ErgSPAj1J49PhIVONFciSGQBLdVJ3RTf3kk87FYiIQgA5Q?e=lN1AOU "
              title="https://accuweathercloud.sharepoint.com/:f:/s/AccuWeatherRecordings/ErgSPAj1J49PhIVONFciSGQBLdVJ3RTf3kk87FYiIQgA5Q?e=lN1AOU "
            >
              https://accuweathercloud.sharepoint.com/:f:/s/AccuWeatherRecordings/ErgSPAj1J49PhIVONFciSGQBLdVJ3RTf3kk87FYiIQgA5Q?e=lN1AOU{" "}
            </a>
          </p>
          <p>
            In order for AccuWeather to ensure compliance with the Terms of Use,
            You agree that upon request by AccuWeather at any time and for any
            reason, You shall provide AccuWeather with information, access to
            and the ability to evaluate Your Use of the APIs and API Data
            (including the AccuWeather Mark(s)) hereunder at no charge.
          </p>
          <p>
            Upon termination of Your subscription to a Package, You agree to
            remove any API Data obtained in connection with APIs from all
            networks and/or storage media, hard drives, etc. and delete all
            information in Your control or possession related to the APIs and
            API Data and to pay all fees hereunder.
          </p>
          <p>
            You will only access (or attempt to access) the APIs by the means
            described in the documentation of the API. If AccuWeather assigns
            Developer credentials (e.g. Developer IDs), You must use them with
            the applicable APIs. You will not misrepresent or mask either Your
            identity or Your API Developer's identity when using the APIs.
          </p>
          <p>
            In the event that the desired use by Developer is not covered by the
            License herein, You may contact AccuWeather at{" "}
            <a href="mailto:sales@accuweather.com">sales@accuweather.com</a> to
            discuss a separate license.
          </p>
          <p>
            AccuWeather may change the APIs at any time without notice and for
            any or no reason, in its sole discretion, and Developer acknowledges
            these actions may make the version of the APIs being utilized by
            Developer obsolete.
          </p>
          <p>
            Upon request, Developer will provide AccuWeather with information,
            access and means to evaluate Developer’s use of the APIs and the
            AccuWeather Mark(s) free-of-charge in order to determine compliance
            with the Terms of Use.
          </p>
          <p>
            All comments, feedback information or materials submitted by
            Developer to AccuWeather shall be considered non-confidential and
            Property of AccuWeather. AccuWeather shall be free to use them on an
            unrestricted, royalty-free basis.
          </p>
          <p>
            AccuWeather is not responsible to Developer or any third party or
            entity in connection with Developer Uses.
          </p>
        </li>
        <li>
          <strong>Intellectual Property Rights.&nbsp;</strong>Developer
          acknowledges and agrees that, as between AccuWeather and Developer,
          AccuWeather is the sole owner of all right, title and interest in and
          to the APIs, the API Data and the AccuWeather Mark(s). AccuWeather
          reserves all right, title and interest in and to the APIs, the API
          Data (excluding any public domain data provided through the API), and
          the AccuWeather Mark(s), including, without limitation, any and all
          worldwide copyright, patent, trademark, trade secret and other
          intellectual property rights therein, and, except for the rights and
          licenses expressly granted hereunder. Neither these Terms of Use nor
          AccuWeather’s performance of its obligations hereunder shall be
          construed as conferring upon You any right or license in or to the
          APIs, the API Data, or the AccuWeather Mark(s), by implication,
          estoppel or otherwise.
          <p>
            Upon the expiration or termination of these Terms of Use for any
            reason, You agree to delete and otherwise discontinue all uses of
            the APIs, the API Data, and the AccuWeather Mark(s).
          </p>
          <p>
            All uses of the AccuWeather Mark(s) will inure solely to the benefit
            of AccuWeather. You disclaim any right, title or interest in or to
            the AccuWeather Mark(s). You agree that You will not contest or
            impair the rights of AccuWeather in and to the AccuWeather Mark(s).
          </p>
          <p>
            Developer shall use the prominent branding of and attribution to
            AccuWeather everywhere the APIs, API Data, and/or other AccuWeather
            content is used and/or displayed by using the AccuWeather Mark(s),
            name, branding, and/or logos. Developer shall indicate AccuWeather’s
            exclusive ownership by affixing proper trademark, copyright, or
            proprietary symbols, or notice statements of ownership.
          </p>
        </li>
        <li>
          <strong>Privacy Policy.&nbsp;</strong>The use of personal information
          is governed by our Privacy Statement located at&nbsp;
          <a href="https://www.accuweather.com/en/privacy">
            https://www.accuweather.com/en/privacy
          </a>
          . Any data or information collected in connection with the Developer
          Uses (e.g. location data, IP addresses, etc.) shall be considered the
          Property of AccuWeather.
          <br />
          &nbsp;
        </li>
        <li>
          <strong>Indemnification.&nbsp;</strong>In connection with the
          Developer Uses, Developer agrees to be solely responsible for any
          Developer Uses, including but not limited to such liability which
          results from infringement of copyrights, patents, other proprietary
          rights, or any other harm (including but not limited to harm caused by
          spyware, malware, worms, Trojan horses and viruses) resulting from
          Developer Uses. Developer agrees to defend, indemnify and hold
          harmless AccuWeather and its parents, affiliates, directors,
          shareholders, representatives, employees and agents (the “Affiliated
          Parties”) from and against any and all claims, demands, liabilities,
          actions or suits asserted by a third party, and all costs,
          liabilities, judgments, expenses and damages, including reasonable
          attorneys’ fees (collectively, “Losses”) suffered, sustained, incurred
          or paid in connection therewith, arising out of, resulting from or
          related to (i) Your access to or use of the APIs and/or the API Data,
          including, without limitation, any claim that Developer Use infringes
          any proprietary, intellectual property or personal right of any third
          party or violates any law; (ii) any breach by Developer of these Terms
          of Use; or (iii) any negligence of the Developer arising in connection
          with these Terms of Use; or (iv.) violation by Developer of any
          applicable laws, rules, regulations or statutes.
          <p>
            Developer will not enter into any settlement that imposes any
            responsibility, liability or obligation on AccuWeather and/or
            Affiliated Parties, or contains any admission or acknowledgement of
            wrongdoing.
          </p>
          <p>
            Developer’s obligations under this paragraph shall survive
            termination of the Terms of Use for any reason.
          </p>
          <p>
            For purposes of clarification, the indemnification obligations of
            Developer shall not include any third party claims that the APIs,
            the API Data, or the AccuWeather Mark(s), as provided by AccuWeather
            without modification and not as implemented by Developer or combined
            with Developer Uses, directly and independently and infringe the
            intellectual property rights of a third party.
          </p>
        </li>
        <li>
          <strong>Technical Support.</strong> AccuWeather has no obligation to
          provide You or any third party with technical support unless otherwise
          agreed to in writing.
          <br />
          &nbsp;
        </li>
        <li>
          <strong>Export Law Compliance and International Obligations.</strong>{" "}
          Developer agrees to comply with all export restrictions and
          regulations of the Department of Commerce or other agency of the US
          Government. You agree that You will not export or otherwise use or
          transfer the APIs, directly or indirectly, to a prohibited country.
          AccuWeather makes no claims that the APIs may be lawfully accessed,
          used or downloaded outside of the United States. Any such use of the
          APIs may not be lawful by certain persons or in particular countries
          or territories. Developer assumes all risk and is solely responsible
          for compliance with applicable laws, rules and regulations in a
          jurisdiction.
          <br />
          &nbsp;
        </li>
        <li>
          <strong>
            Compliance with Law, Third Party Rights, and Other AccuWeather Terms
            of Service.{" "}
          </strong>
          Developer will comply with all applicable laws, regulations, and third
          party rights (including without limitation laws regarding the import
          or export of data or software, privacy, and local laws). You will not
          use the APIs or the API Data to encourage or promote illegal activity
          or violation of third party rights. You will not violate any other
          terms of service with AccuWeather (or its affiliates). Developer
          represents and warrants that all Developer Use and any products,
          services, software, equipment, technology or materials of any kind
          utilized by Developer in connection with the use or display of the
          APIs or the API Data does not and will not violate or infringe upon
          the intellectual property rights or other rights of any third party or
          violate any applicable rule, law, regulation and will not create any
          liability for AccuWeather.
          <p>
            If You use the APIs as an interface to, or in conjunction with other
            AccuWeather products or services, then the terms for those other
            products or services also apply. If there is a conflict between
            these terms and additional terms applicable to a separate agreement
            between You and AccuWeather or an affiliate in common ownership with
            AccuWeather, the additional terms will control for that conflict.
          </p>
        </li>
        <li>
          <strong>Limitations on APIs. </strong>AccuWeather sets and enforces
          limits on Your use of the APIs (e.g. limiting the number of API
          requests that You may make or the number of users You may serve), in
          our sole discretion. You agree to, and will not attempt to circumvent,
          such limitations documented with the APIs. If Developer desires to use
          any APIs beyond these limits or the license described herein,
          Developer must obtain AccuWeather's express consent. AccuWeather may
          decline such request for any reason or no reason; or condition
          acceptance subject to additional terms and/or charges for that use. To
          seek such approval, contact AccuWeather.
          <br />
          &nbsp;
        </li>
        <li>
          <strong>Additional Terms and Conditions.</strong>
          <p>
            <strong>AccuWeather Intellectual Property. </strong>AccuWeather
            retains all rights, title and interest, including rights of
            trademark, copyright, and patent, in those things defined herein as
            “Property of AccuWeather”. “Property of AccuWeather” is hereby
            defined to include all intellectual property rights, title and
            interest in (a) the APIs, any reports, forecasts, graphics, data,
            equipment, usage data, distribution rights, and information
            furnished pursuant hereto; the trademark AccuWeather (hereafter the
            “AccuWeather Mark(s)”), identifiers embodying “ACCU” as all or a
            portion thereof; any tradenames, trademarks, service names, symbols,
            identifiers, URL’s, formats, designs, devices, patents or other
            proprietary products, information or distribution rights owned by or
            licensed to AccuWeather or its affiliates, and (b) any other
            tradenames, trademarks, service marks, symbols, identifiers, URL’s,
            formats, designs, devices, distribution rights, identifiers
            embodying “ACCU” as all or a portion thereof; any other tradenames,
            trademarks, service names, symbols, identifiers, URL’s formats,
            designs and devices, or distribution rights; as any may be used to
            designate the products and services furnished by AccuWeather or used
            in connection with the products and services by either party.
            Developer agrees to disclose to AccuWeather any uses of the Property
            of AccuWeather of which it becomes aware which fall outside the
            scope of the license granted hereunder and to either terminate the
            unlicensed use or work to secure a license from AccuWeather for the
            use. Following termination of the Terms of Use, Developer shall not
            use the Property of AccuWeather, whether protectable under law or
            not, and Developer shall not duplicate or imitate same in any
            manner. AccuWeather shall have the right to use, collect, store,
            aggregate and otherwise exploit any information and data which may
            be passed by Developer and collected by AccuWeather (‘usage data’),
            consistent with its Privacy Policy, in connection with the APIs and
            the API Data (e.g. location requests, IP address). Developer also
            acknowledges that AccuWeather and/or its affiliated companies own,
            or have rights to license, a family of patents generally covering
            the transmission of weather and other data over a broadcast network
            (cellular, satellite or other) to a communication device and based
            upon the location of the device and the user’s identification
            (“Location Based Patents” or “LBPs”). By way of example, but not
            limitation, the types of communication devices covered by these LBPs
            may include mobile phones, laptops, computers, tablets, navigation
            devices, personal digital assistants, televisions, digital signs and
            other similar devices. Developer may not use the LBPs unless
            specifically authorized by AccuWeather to do so in a separate
            written license agreement.
          </p>
          <p>
            <strong>Events Beyond AccuWeather's Control.</strong> AccuWeather
            shall not be held responsible for any failure or malfunction in
            power or communications nor the failure or refusal of third parties
            to perform, continue or renew their contractual arrangements with
            AccuWeather nor inability to perform occasioned by such or by labor
            strife, war, riot or other events beyond the control of AccuWeather.
            If the Service or delivery thereof is disrupted by any such
            event(s), AccuWeather’s obligations hereunder shall be suspended
            during the period of occurrence of such disrupting event(s);
            however, AccuWeather’s obligations hereunder shall be extended for
            one day for each full day of interruption. AccuWeather’s failure to
            perform or breach of the Terms of Use, resulting from any event(s)
            not specified above, shall cause it to be liable to Developer (if
            such liability is determined by a court of law) in a maximum
            aggregate amount not to exceed $100.00 USD. This shall be the
            exclusive remedy hereunder. THE AVAILABILITY OF THE APIs AND THE API
            DATA IS DEPENDENT UPON OPEN COMMUNICATIONS NETWORKS PROVIDED BY
            THIRD PARTIES AND ACCUWEATHER IS NOT RESPONSIBLE FOR FAILURES IN THE
            APIs AND THE API DATA FOR THIS OR ANY OTHER REASON. DEVELOPER IS
            ADVISED AND INFORMED THAT THE GOVERNMENT ISSUES OFFICIAL WATCHES,
            WARNINGS, ADVISORIES, BULLETINS AND OTHER COMMUNICATIONS. DEVELOPER
            SHOULD KEEP ADVISED OF SAME. DEVELOPER AND THIRD PARTIES ARE SOLELY
            RESPONSIBLE FOR ACTION OR LACK OF ACTION TAKEN TO PRESERVE LIFE OR
            PROPERTY.&nbsp;
          </p>
          <p>
            <strong>No Warranties.</strong> AccuWeather makes no express or
            implied warranties, guaranties or affirmations that weather will
            occur or has occurred as the reports, forecasts, graphics, data,
            briefings or information comprising the Service state, represent or
            depict and AccuWeather and its affiliates shall have no
            responsibility or liability whatsoever to Developer or any other
            person or entity, parties and non-parties alike, for any
            inconsistency, inaccuracy, or omission for weather or events
            predicted or depicted, reported, occurring or occurred. AccuWeather
            MAKES NO WARRANTIES HEREUNDER, AND THIS DISCLAIMER OF WARRANTIES
            INCLUDES, BUT IS NOT LIMITED TO, THE WARRANTIES OR MERCHANTABILITY
            AND FITNESS FOR A PARTICULAR PURPOSE. AccuWeather offers no
            information or advice with respect to investments, purchases, sales
            or transactions of any kind affecting commodities, securities,
            tradeables or other products or services, and AccuWeather disclaims
            any and all liability for reliance on the information provided in
            the APIs and/or the API Data. To the extent permitted by law or as
            expressly set forth herein, AccuWeather provides the APIs and API
            Data “as is” with no warranties of any kind.
          </p>
          <p>
            <strong>English Language.</strong> The parties agree to the Terms of
            Use in the English language. Any translation of the Terms of Use is
            provided for convenience only and the English Terms of Use will
            solely govern relationship between the parties.
          </p>
          <p>
            <strong>Choice of Law.</strong> The parties agree to submit to the
            Pennsylvania Courts only, any dispute arising out of the Terms of
            Use or related thereto and consent to the jurisdiction of said
            courts and further agree that any and all matters of dispute shall
            be adjudicated, governed and controlled under and by Pennsylvania
            law and this paragraph shall supersede any conflicting choice of law
            rules.
          </p>
          <p>
            <strong>Assignability.</strong> Neither the Terms of Use nor any
            rights or obligations hereunder may be assigned by Developer without
            the express written consent of AccuWeather and written acceptance of
            the Terms of Use by the assignee.
          </p>
          <p>
            <strong>No Agency.</strong> Nothing herein shall be deemed to create
            an agency, partnership, employment relationship, or joint venture
            between the parties. Any manner of suit, action or claim of any
            nature or kind against AccuWeather by Developer or third parties
            shall be brought within one year from date of occurrence of the
            earliest event giving rise to such suit, action or claim.
            AccuWeather shall have no responsibility to retain records of
            transmissions by or between AccuWeather and Developer or records of
            forecasts, data, graphics or products in whatever form or nature,
            that may have been produced or supplied under the Terms of Use and
            AccuWeather shall not be required to produce same by any adverse
            party.
          </p>
          <p>
            <strong>Severability.</strong> The invalidity of any paragraph,
            subparagraph or portion of the Terms of Use shall not affect the
            validity of any other paragraph, subparagraph or portion hereof and
            such invalid paragraph, subparagraph or portion thereof shall be
            reformed to achieve the intended spirit and intent of such provision
            between the parties.&nbsp;
          </p>
          <p>
            <strong>Limitation on Damages. </strong>In no event will AccuWeather
            be liable to You for indirect, incidental, exemplary, punitive,
            compensatory, or consequential damages arising out of or in
            connection with the Terms of Use, under any theory of liability,
            whether in contract, tort (including negligence), breach of
            warranty, or other theory. Additionally, in no event will
            AccuWeather’s aggregate, cumulative liability to You regarding any
            and all claims and causes of action arising out of or relating to
            the Terms of Use under any theory of liability, whether in contract,
            tort (including negligence), breach of warranty or other theory,
            exceed total fees paid by You hereunder during the preceding twelve
            (12) month period.&nbsp;
          </p>
          <p>
            <strong>Termination.</strong> Developer’s use of the APIs which is
            inconsistent with the grant of license herein or any delinquency in
            Developer’s payment obligations shall be the basis for immediate
            termination of the Package and the License granted pursuant to the
            Terms of Use by AccuWeather or the imposition of appropriate charges
            for the uses or both. This shall be in addition to, and not in lieu
            of, other legal and equitable remedies available to AccuWeather and
            shall not affect the sums due hereunder.
          </p>
          <p>
            <strong>Waiver.</strong> The failure of AccuWeather to require the
            performance of any term of the Terms of Use or the waiver by
            AccuWeather of any breach under the Terms of Use shall not prevent a
            subsequent enforcement of such term by AccuWeather nor be deemed a
            waiver of any subsequent breach.
          </p>
          <p>
            <strong>Headings and Captions.</strong> The headings and captions
            used in the Terms of Use are for convenience only, they are not to
            be considered a part of the Terms of Use, they in no way define,
            limit, construe or describe the scope or intent of the paragraphs of
            the Terms of Use nor in any way affect them.
          </p>
          <p>
            <strong>Press Releases and Publicity.</strong> Developer shall
            obtain AccuWeather’s approval prior to press releases or other
            public announcements regarding AccuWeather, its APIs, the API Data,
            and/or other content and information. Approval requests must be sent
            a minimum of two weeks prior to the planned distribution date to the
            AccuWeather communications team at{" "}
            <a href="mailto:pr@accuweather.com">pr@accuweather.com</a>. Press
            releases or other public announcements shall comply with
            AccuWeather’s intellectual property requirements and guidelines
            stated herein.
          </p>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p id="acceptableuse">
        <strong>Acceptable Use Policy.</strong>&nbsp;You agree not to use the
        APIs or the API Data to:
      </p>
      <p>
        * upload, post or otherwise transmit any data or content that is
        unlawful, harmful, threatening, abusive, harassing, tortious,
        defamatory, vulgar, obscene, libelous, invasive of another's privacy or
        without another's permission, hateful, or racially, ethnically or
        otherwise objectionable to any person for any reason, natural or
        corporate;
      </p>
      <p>
        * harm minors in any way; impersonate any person or entity, including,
        but not limited to, an AccuWeather official, forum leader, guide or
        host, or falsely state or otherwise misrepresent Your affiliation with a
        person or entity;
      </p>
      <p>
        * upload, post or otherwise transmit any falsified, composite, or
        otherwise non-authentic depictions of events, locations, landmarks,
        entities or persons (spoofs);
      </p>
      <p>
        * upload, post or otherwise transmit any content that You do not have a
        right to transmit under any law or under contractual or fiduciary
        relationships (such as inside information, proprietary and confidential
        information learned or disclosed as part of employment relationships or
        under nondisclosure agreements);
      </p>
      <p>
        * upload, post or otherwise transmit any content that infringes any
        patent, trademark, trade secret, copyright or other proprietary or
        intellectual property rights of any party;
      </p>
      <p>
        * upload, post or otherwise transmit any content that promotes
        discrimination based on race, sex, religion, nationality, disability,
        sexual orientation or age;
      </p>
      <p>
        * upload, post or otherwise transmit any unsolicited or unauthorized
        advertising, promotional materials, "junk mail," "spam," "chain
        letters," "pyramid schemes," "phishing" or any other form of
        solicitation, including, without limitation, the solicitation of users
        to become subscribers of other on-line information services competitive
        with AccuWeather;
      </p>
      <p>
        * upload, post or otherwise transmit any material that contains software
        viruses or any other computer code, files or programs designed to
        interrupt, destroy or limit the functionality of any computer software
        or hardware or telecommunications equipment;
      </p>
      <p>
        * interfere with or disrupt AccuWeather or servers or networks connected
        to AccuWeather, or disobey any requirements, procedures, policies or
        regulations of networks connected to AccuWeather;
      </p>
      <p>
        * "stalk" or otherwise harass another; collect or store Personally
        Identifiable Information about other users;
      </p>
      <p>
        * promote or provide instructional information about illegal activities,
        promote physical harm or injury against any group or individual, or
        promote any act of cruelty to animals, including, but not limited to,
        providing instructions on how to assemble bombs, grenades and other
        weapons, and creating "Crush" sites;
      </p>
      <p>
        * use Your access or AccuWeather-hosted site (or directory) as storage
        for remote loading or as a door or signpost to another home page,
        whether inside or beyond the AccuWeather site, without express written
        permission from AccuWeather.
      </p>
    </div>
  );
};

export default TosPrompt;
