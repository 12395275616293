import { cn } from "../components/cn";
import {
  CheckIcon,
  CopyIcon,
  EyeIcon,
  EyeOffIcon,
  RotateCwIcon,
  Trash2Icon,
  PencilIcon,
  MoreVerticalIcon,
} from "lucide-react";
import { ReactNode, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button } from "zudoku/ui/Button";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "zudoku/ui/AlertDialog";
import { Input } from "zudoku/ui/Input";
import { ActionButton } from "zudoku/ui/ActionButton";
import { Callout } from "zudoku/components";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "zudoku/ui/DropdownMenu"; // Import necessary components
import { Skeleton } from "zudoku/ui/Skeleton";

export type Key = {
  id?: string;
  key: string;
  createdOn: string;
  expiresOn: string;
};

type ApiKeyService = {
  rollKey?: (id: string) => Promise<void>;
  deleteKey?: (id: string) => Promise<void>;
  deleteApp?: (id: string) => Promise<void>;
  renameApp?: (data: { name: string }) => Promise<void>;
  updateKeyDescription?: (apiKey: {
    id: string;
    description: string;
  }) => Promise<void>;
  getUsage?: (apiKeys: string[]) => Promise<void>;
  createKey?: (apiKey: {
    description: string;
    expiresOn?: string;
  }) => Promise<void>;
};

export const ApiKeyGroup = ({
  id,
  description,
  title,
  keys,
  service,
}: {
  id: string;
  title: string;
  description?: string;
  keys: Key[];
  service: ApiKeyService;
}) => {
  const [confirmInput, setConfirmInput] = useState("");
  const [newAppNameInput, setNewAppNameInput] = useState("");
  const queryClient = useQueryClient();
  const [error, setError] = useState<string | null>(null);
  const [openRenameDialog, setOpenRenameDialog] = useState(false);
  const [openDeleteAppDialog, setOpenDeleteAppDialog] = useState(false);

  const deleteKeyMutation = useMutation({
    mutationFn: (id: string) => {
      if (!service.deleteKey) {
        throw new Error("Deleting an API Key is not implemented");
      }
      setError(null);

      return service.deleteKey(id);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ["api-keys"] });
    },
    onError: (error: any) => {
      setError((error as { message: string }).message);
    },
  });

  const rollKeyMutation = useMutation({
    mutationFn: (id: string) => {
      if (!service.rollKey) {
        throw new Error("Rolling an API Key is not implemented");
      }
      setError(null);

      return service.rollKey(id);
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["api-keys"] }),
    onError: (error: any) => {
      setError((error as { message: string }).message);
    },
  });

  const deleteApp = useMutation({
    mutationFn: (id: string) => {
      if (!service.deleteApp) {
        throw new Error("Deleting App is not implemented");
      }
      setError(null);
      setConfirmInput("");

      return service.deleteApp(id);
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["api-keys"] }),
    onError: (error: any) => {
      setError((error as { message: string }).message);
    },
  });

  const renameApp = useMutation({
    mutationFn: (params: { id: string; newName: string }) => {
      if (!service.renameApp) {
        throw new Error("Rename App is not implemented");
      }
      setError(null);
      setNewAppNameInput("");
      return service.renameApp({ name: params.newName });
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["api-keys"] }),
    onError: (error: any) => {
      setError((error as { message: string }).message);
    },
  });

  return (
    <div>
      {error && <Callout type="caution">{error}</Callout>}
      <div className="flex gap-2 items-center mb-1">
        {renameApp.isPending ? (
          <div className="w-full">
            <Skeleton className="h-4 w-8/12 rounded-full bg-gray-300" />
          </div>
        ) : (
          <div className="font-semibold w-full truncate" title={title}>
            {title}
          </div>
        )}

        <DropdownMenu modal={false}>
          <DropdownMenuTrigger asChild>
            <ActionButton
              variant="ghost"
              className="ml-auto flex-shrink-0"
              size="icon"
            >
              <MoreVerticalIcon size={16} />
            </ActionButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {service.renameApp && (
              <DropdownMenuItem>
                <ActionButton
                  isPending={renameApp.isPending}
                  title="Rename this app?"
                  variant="ghost"
                  onClick={() => {
                    setNewAppNameInput(title);
                    setOpenRenameDialog(true);
                  }}
                >
                  <div className="flex items-center gap-2">
                    <PencilIcon size={16} /> <span>Rename App</span>
                  </div>
                </ActionButton>
              </DropdownMenuItem>
            )}
            {service.deleteApp && (
              <DropdownMenuItem>
                <ActionButton
                  isPending={deleteApp.isPending}
                  variant="ghost"
                  onClick={() => setOpenDeleteAppDialog(true)}
                >
                  <div className="flex items-center gap-2">
                    <Trash2Icon size={16} /> Delete App
                  </div>
                </ActionButton>
              </DropdownMenuItem>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <ul className={cn("w-full flex flex-col gap-4")}>
        {[...keys]
          .sort((a, b) =>
            a.expiresOn && b.expiresOn
              ? new Date(a.expiresOn).getTime() -
                new Date(b.expiresOn).getTime()
              : a.expiresOn
                ? 1
                : -1,
          )
          .map((key, i) => (
            <li className="flex gap-0.5 flex-col" key={key.id}>
              {description}
              <div className="flex flex-row gap-2">
                <RevealApiKey apiKey={key.key}>
                  {i === 0 && (
                    <AlertDialog>
                      <AlertDialogTrigger asChild>
                        <ActionButton
                          isPending={rollKeyMutation.isPending}
                          variant="outline"
                          size="icon"
                          disabled={rollKeyMutation.isPending}
                          className="flex-shrink-0"
                        >
                          <RotateCwIcon size={16} />
                        </ActionButton>
                      </AlertDialogTrigger>
                      <AlertDialogContent>
                        <AlertDialogHeader>
                          <AlertDialogTitle>
                            Do you want to roll this key?
                          </AlertDialogTitle>
                          <AlertDialogDescription>
                            This will invalidate the current key and generate a
                            new. Make sure to update your applications with the
                            new key.
                          </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                          <AlertDialogCancel>Cancel</AlertDialogCancel>
                          <AlertDialogAction asChild>
                            <button
                              onClick={() => rollKeyMutation.mutate(key.id)}
                            >
                              Continue
                            </button>
                          </AlertDialogAction>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialog>
                  )}
                  {key.expiresOn &&
                    new Date(key.expiresOn) < new Date() &&
                    service.deleteKey && (
                      <AlertDialog>
                        <AlertDialogTrigger asChild>
                          <Button
                            variant="outline"
                            size="icon"
                            disabled={deleteKeyMutation.isPending}
                          >
                            <Trash2Icon size={16} />
                          </Button>
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                          <AlertDialogHeader>
                            <AlertDialogTitle>
                              Do you want to delete this key?
                            </AlertDialogTitle>
                            <AlertDialogDescription>
                              This key has expired and is no longer valid and
                              can't be used to authenticate requests.
                            </AlertDialogDescription>
                          </AlertDialogHeader>
                          <AlertDialogFooter>
                            <AlertDialogCancel>Cancel</AlertDialogCancel>
                            <AlertDialogAction asChild>
                              <button
                                onClick={() => deleteKeyMutation.mutate(key.id)}
                              >
                                Continue
                              </button>
                            </AlertDialogAction>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialog>
                    )}
                </RevealApiKey>
              </div>
              <div className="text-muted-foreground text-sm">
                {key.createdOn && (
                  <>Created on {new Date(key.createdOn).toLocaleDateString()}</>
                )}
                {key.createdOn && key.expiresOn && ", "}
                {key.expiresOn && (
                  <>Expires on {new Date(key.expiresOn).toLocaleDateString()}</>
                )}
              </div>
            </li>
          ))}
      </ul>

      <AlertDialog open={openRenameDialog} onOpenChange={setOpenRenameDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              Are you sure you want to rename this app?
            </AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently rename your
              App.
              <span className="font-medium mt-2 block">
                To rename this app, type the new name you want to assign it.
              </span>
              <Input
                placeholder={title}
                className="w-full mt-4"
                type="text"
                maxLength={64}
                value={newAppNameInput}
                onChange={(e) => setNewAppNameInput(e.target.value)}
              />
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>

            <AlertDialogAction asChild>
              <ActionButton
                isPending={renameApp.isPending}
                onClick={() =>
                  renameApp.mutate({ id, newName: newAppNameInput })
                }
                variant="destructive"
              >
                Rename App
              </ActionButton>
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog
        open={openDeleteAppDialog}
        onOpenChange={setOpenDeleteAppDialog}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              Are you sure you want to delete this app?
            </AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete your
              App and Key. <br />
              <span className="font-medium mt-2 block">
                To delete this app, type{" "}
                <span className="text-destructive">{title} </span>
                to confirm you want to delete it.
              </span>
              <Input
                placeholder={title}
                onChange={(e) => setConfirmInput(e.target.value)}
                value={confirmInput}
                className="w-full mt-4"
                type="text"
              />
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>

            <AlertDialogAction asChild>
              <ActionButton
                isPending={deleteApp.isPending}
                disabled={title !== confirmInput}
                onClick={() => deleteApp.mutate(id)}
                variant="destructive"
              >
                Delete App
              </ActionButton>
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

const RevealApiKey = ({
  apiKey,
  children,
}: {
  apiKey: string;
  children?: ReactNode;
}) => {
  const [revealed, setRevealed] = useState(false);
  const [copied, setCopied] = useState(false);

  return (
    <div className="flex gap-2 items-center text-sm w-full">
      <div className="border rounded bg-gray-100 dark:bg-transparent p-1 font-mono truncate h-9 items-center flex px-2 w-full">
        {revealed ? apiKey : "•".repeat(apiKey.length)}
      </div>
      <Button
        variant="outline"
        onClick={() => setRevealed((prev) => !prev)}
        size="icon"
        className="flex-shrink-0"
      >
        {revealed ? <EyeOffIcon size={16} /> : <EyeIcon size={16} />}
      </Button>
      <Button
        variant="outline"
        className="flex-shrink-0"
        onClick={() => {
          void navigator.clipboard.writeText(apiKey).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
          });
        }}
        size="icon"
      >
        {copied ? <CheckIcon size={16} /> : <CopyIcon size={16} />}
      </Button>
      {children}
    </div>
  );
};
