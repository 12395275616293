import { ActionButton } from "zudoku/ui/ActionButton";
import { useSignedMutation } from "../useQuery";

interface EmailVerificationPromptProps {
  isRefetching: boolean;
  onRefetch: () => void;
}

export const EmailVerificationPrompt = ({
  isRefetching,
  onRefetch,
}: EmailVerificationPromptProps) => {
  const emailVerificationMutation = useSignedMutation(
    `/v1/developer/email-verification`
  );

  return (
    <section className="max-w-screen-xl mx-auto py-24">
      <div className="grid place-items-center h-full">
        <div className="flex flex-col gap-2">
          <h2 className="text-center">Verify your email address</h2>
          <p>
            You need to verify your email address before you can manage your
            subscriptions.{" "}
          </p>
          <ActionButton
            isPending={emailVerificationMutation.isPending}
            variant="secondary"
            onClick={() => emailVerificationMutation.mutate({})}
          >
            Resend verification email
          </ActionButton>
          <ActionButton
            isPending={isRefetching}
            variant="ghost"
            onClick={onRefetch}
          >
            I have verified my email
          </ActionButton>
        </div>
      </div>
    </section>
  );
};
