import { useSessionStorage } from "@uidotdev/usehooks";
import { useAuth } from "zudoku/components";

type ShoppingCartItem<G> = {
  id: string;
  quantity?: number;
  value: G;
};

export const useShoppingCart = <ShoppingCartValue,>({
  defaultItems = [],
}:
  | {
      defaultItems: ShoppingCartItem<ShoppingCartValue>[];
    }
  | undefined) => {
  const auth = useAuth();
  const sub =
    auth && auth.profile && auth.profile.sub
      ? auth.profile.sub
      : Date.now().toString;
  const [items, setItems] = useSessionStorage<
    ShoppingCartItem<ShoppingCartValue>[]
  >(`shopping-cart-${sub}`, defaultItems);

  const addToCart = (itemId: string, item: ShoppingCartValue) => {
    setItems((prevCart) => [...prevCart, { id: itemId, value: item }]);
  };
  const removeFromCart = (itemId: string) => {
    setItems((prevCart) => prevCart.filter((item) => item.id !== itemId));
  };
  const hasItem = (itemId: string) => {
    return !!items.find((item) => item.id === itemId);
  };
  const clearCart = (items: ShoppingCartItem<ShoppingCartValue>[] = []) =>
    setItems(items);

  return {
    items,
    length: items.length,
    addToCart,
    removeFromCart,
    hasItem,
    clearCart,
  };
};
