import * as React from "react";
import { SVGProps } from "react";
export const MinuteCastWeatherIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g fill="#57A0EE" clipPath="url(#a)">
      <path d="M11.762 22.229a.887.887 0 0 1 0 1.771 11.764 11.764 0 1 1 5.76-22.016l-.247-.644a.99.99 0 1 1 1.846-.703l.977 2.568c.036.097.058.2.062.303a.976.976 0 0 1-.685 1.101l-2.625.805a.985.985 0 1 1-.57-1.885l.25-.076a9.994 9.994 0 1 0-4.768 18.775Zm9.96-16.416c-.687-1.034-2.275-.127-1.673 1.017l.027.043c.322.5.597 1.028.822 1.578a.98.98 0 1 0 1.813-.748 11.927 11.927 0 0 0-.99-1.89h.002Zm-6.63 15.763a.979.979 0 0 0-.093 1.81.98.98 0 0 0 .749.038 11.774 11.774 0 0 0 1.941-.889.98.98 0 0 0-.976-1.699c-.52.29-1.062.538-1.621.74Zm4.322-3.021c-.715.867.408 2.12 1.383 1.367.05-.043.097-.09.139-.14a12.54 12.54 0 0 0 1.193-1.77.979.979 0 1 0-1.656-1.043c-.32.55-.673 1.08-1.059 1.586Zm2.125-4.846a.977.977 0 0 0 1.88.504.941.941 0 0 0 .06-.22c.105-.705.147-1.418.125-2.13-.055-1.209-1.838-1.295-1.954-.023V12a10.2 10.2 0 0 1-.107 1.709h-.004Z" />
      <path d="M4 14.993 4.733 9h1.534l1.29 4.203L8.848 9h1.517l.75 5.993H9.547l-.405-3.236-.828 3.236H6.78l-.817-3.166L5.574 15 4 14.993ZM12.108 9v5.993h1.635V9h-1.635ZM14.882 9v5.993H16.5v-3.339l2.203 3.34H20V9h-1.635v3.336L16.179 9h-1.297Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h23.609v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
