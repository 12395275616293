import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "zudoku/ui/Card";
import { Input } from "zudoku/ui/Input";
import { CardCvvElement } from "@recurly/react-recurly";
import { Button } from "zudoku/ui/Button";
import { CreditCardIcon } from "lucide-react";
import type { ReactNode } from "react";

export const CurrentBillingInformation = ({
  name,
  number,
  onRequestUpdate,
  children,
}: {
  name: string;
  number: string;
  onRequestUpdate: () => void;
  children?: ReactNode;
}) => {
  return (
    <Card className="w-full max-w-md">
      <CardHeader className="border-b">
        <CardTitle>Current Billing Information</CardTitle>
        <CardDescription>
          Your billing address and payment information
        </CardDescription>
      </CardHeader>
      <CardContent className="border-t pt-4 flex flex-col gap-2">
        <div className="space-y-1">
          <Input
            id="firstName"
            disabled={true}
            data-recurly="first_name"
            placeholder={name}
            name="firstName"
            required
          />
        </div>
        <div className="flex flex-row items-center gap-2">
          <Input
            disabled={true}
            id="number"
            defaultValue={number}
            data-recurly="number"
            name="number"
            required
          />

          <div className="[&_.recurly-element]:rounded-md [&_.recurly-element]:mt-0 [&_.recurly-element]:h-9  border-border">
            <CardCvvElement
              style={{
                placeholder: { content: "CVV" },
                fontFamily: "Geist",
                fontSize: "0.875rem",
              }}
            />
          </div>
        </div>
      </CardContent>
      <CardFooter>
        <div className="w-full">
          <div className="items-center gap-2">
            <Button
              variant="outline"
              type="button"
              className="w-full"
              onClick={onRequestUpdate}
            >
              <CreditCardIcon size={16} className="mr-2" />
              Update Billing Information
            </Button>
          </div>
          <div className="items-center gap-2 w-full">{children}</div>
        </div>
      </CardFooter>
    </Card>
  );
};
