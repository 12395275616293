import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "zudoku/ui/Dialog";
import { Button } from "zudoku/ui/Button";
import { PlusIcon } from "lucide-react";
import { Label } from "zudoku/ui/Label";
import { Input } from "zudoku/ui/Input";
import { useSignedMutation } from "../useQuery";
import { useState } from "react";
import { ActionButton } from "zudoku/ui/ActionButton";

export const AddAppDialog = ({
  subscriptionId,
}: {
  subscriptionId: string;
}) => {
  const [name, setName] = useState("");

  const addAppMutation = useSignedMutation(
    `/v1/developer/subscriptions/${subscriptionId}/apps`,
    {
      method: "POST",
    },
  );

  return (
    <Dialog>
      <DialogTrigger asChild>
        <ActionButton
          variant="secondary"
          disabled={addAppMutation.isPending}
          isPending={addAppMutation.isPending}
        >
          <div className="flex items-center gap-2">
            <PlusIcon size={18} className="mr-1" />
            Add App
          </div>
        </ActionButton>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px] p-0">
        <DialogHeader className="p-6 border-b">
          <DialogTitle>Add new App</DialogTitle>
          <DialogDescription>
            Creating a new app will assign it a key.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4 p-6">
          <div className="grid items-center gap-4">
            <Label htmlFor="name">App Name</Label>
            <Input
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="My New App"
              className="col-span-3"
            />
          </div>
        </div>
        <DialogFooter className="p-6 sm:justify-between flex">
          <DialogClose asChild>
            <Button variant="outline">Cancel</Button>
          </DialogClose>
          <DialogClose asChild>
            <Button
              type="submit"
              onClick={() => {
                addAppMutation.mutate({ data: { name } });
                setName("");
              }}
            >
              Add App
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
