import { Button } from "zudoku/ui/Button";
import { useSignedMutation } from "../useQuery";
import { Input } from "zudoku/ui/Input";
import { Label } from "zudoku/ui/Label";
import { useForm } from "react-hook-form";
import { cn } from "../components/cn";
import { useAuth } from "zudoku/components";

interface ProfileFormData {
  firstName: string;
  lastName: string;
  businessName: string;
  phoneNumber: string;
}

const CompleteProfilePrompt = ({ onComplete }: { onComplete: () => void }) => {
  const auth = useAuth();
  const email =
    auth && auth.profile && auth.profile.email ? auth.profile.email : undefined;
  const developerMutation = useSignedMutation(
    `/v1/developer`,
    {
      method: "PATCH",
    },
    {
      onSuccess: () => onComplete(),
    },
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ProfileFormData>({
    defaultValues: {
      firstName: "",
      lastName: "",
      businessName: "",
      phoneNumber: "",
    },
  });

  const onSubmit = (data: ProfileFormData) => {
    developerMutation.mutate({
      data: {
        email,
        firstName: data.firstName,
        lastName: data.lastName,
        businessName: data.businessName,
        phoneNumber: data.phoneNumber,
      },
    });
  };

  return (
    <div className="w-full mt-12">
      <div className="m-auto prose dark:prose-invert max-w-xl">
        <div className="text-center">
          <h2 className="my-0 dark:text-white">Complete Your Profile</h2>
          <p className="m-0">In order to gain access to AccuWeather APIs</p>
        </div>
        <div className="p-6 mt-4 not-prose">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <Label htmlFor="firstName">First Name *</Label>
                <Input
                  id="firstName"
                  className={cn(errors.firstName && "border-red-500")}
                  {...register("firstName", {
                    required: "First name is required",
                  })}
                  placeholder="John"
                />
                {errors.firstName && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.firstName.message}
                  </p>
                )}
              </div>
              <div>
                <Label htmlFor="lastName">Last Name *</Label>
                <Input
                  id="lastName"
                  className={cn(errors.lastName && "border-red-500")}
                  {...register("lastName", {
                    required: "Last name is required",
                  })}
                  placeholder="Doe"
                />
                {errors.lastName && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.lastName.message}
                  </p>
                )}
              </div>
            </div>
            <div>
              <Label htmlFor="businessName">Business Name *</Label>
              <Input
                id="businessName"
                className={cn(errors.businessName && "border-red-500")}
                {...register("businessName", {
                  required: "Business name is required",
                })}
                placeholder="Acme Corp"
              />
              {errors.businessName && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.businessName.message}
                </p>
              )}
            </div>
            <div>
              <Label htmlFor="phoneNumber">Phone Number</Label>
              <Input
                id="phoneNumber"
                className={cn(errors.phoneNumber && "border-red-500")}
                {...register("phoneNumber")}
                placeholder="+1 (555) 123-4567"
              />
              {errors.phoneNumber && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.phoneNumber.message}
                </p>
              )}
            </div>
            <Button
              type="submit"
              className="w-full"
              variant="secondary"
              disabled={developerMutation.isPending}
            >
              Continue
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CompleteProfilePrompt;
