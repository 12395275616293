import { Head, useAuth } from "zudoku/components";
import { QueryClientProvider } from "@tanstack/react-query";
import { useSignedMutation, useSignedQuery } from "../useQuery";
import { queryClient } from "../queries/client";
import { useForm } from "react-hook-form";
import { Card, CardContent, CardHeader, CardTitle } from "zudoku/ui/Card";
import { Label } from "zudoku/ui/Label";
import { Input } from "zudoku/ui/Input";
import { ActionButton } from "zudoku/ui/ActionButton";
import { Callout } from "zudoku/ui/Callout";
import { cn } from "../components/cn";

const Page = () => {
  return (
    <section className="flex flex-col py-12 gap-5 lg:gap-10 max-w-screen-xl mx-auto not-prose">
      <Head>
        <title>Profile</title>
      </Head>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-[1fr_max-content]">
        <div className="space-y-4">
          <ProfileForm />
          <PasswordForm />
        </div>
      </div>
    </section>
  );
};

type ProfileFormData = {
  firstName: string;
  lastName: string;
  businessName: string;
  phoneNumber: string;
};

export const ProfileForm = () => {
  const auth = useAuth();
  const developerQuery = useSignedQuery(`/v1/developer`, { staleTime: 0 });
  const updateDeveloperMutation = useSignedMutation(`/v1/developer`, {
    method: "PATCH",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ProfileFormData>({
    defaultValues: {
      firstName: developerQuery.data.firstName,
      lastName: developerQuery.data.lastName,
      businessName: developerQuery.data.businessName,
      phoneNumber: developerQuery.data.phoneNumber,
    },
  });

  return (
    <div className="max-w-xl mx-auto w-full">
      <form
        onSubmit={handleSubmit((data) =>
          updateDeveloperMutation.mutate({
            data: {
              ...Object.fromEntries(
                Object.entries(data).filter(([_, v]) => v !== null),
              ),
              id: developerQuery.data.id,
              email: auth.profile.email,
            },
          }),
        )}
        className="space-y-4"
      >
        <h2 className="text-2xl font-semibold">Profile</h2>
        {updateDeveloperMutation.isSuccess && (
          <Callout type="info">Profile updated successfully</Callout>
        )}
        {updateDeveloperMutation.isError && (
          <Callout type="danger">
            {(updateDeveloperMutation.error as Error)?.message ||
              "Failed to update profile"}
          </Callout>
        )}
        <Card className="overflow-hidden">
          <CardHeader className="border-b bg-muted">
            <div className="flex justify-between items-center w-full">
              <CardTitle>Account Information</CardTitle>
              <ActionButton
                type="submit"
                isPending={updateDeveloperMutation.isPending}
              >
                Update
              </ActionButton>
            </div>
          </CardHeader>
          <CardContent className="mt-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <Label htmlFor="firstName">First Name *</Label>
                <Input
                  id="firstName"
                  className={cn(errors.firstName && "border-red-500")}
                  {...register("firstName", {
                    required: "First name is required",
                  })}
                  placeholder="First Name"
                />
                {errors.firstName && (
                  <p className="text-red-500 text-sm">
                    {errors.firstName.message}
                  </p>
                )}
              </div>
              <div>
                <Label htmlFor="lastName">Last Name *</Label>
                <Input
                  id="lastName"
                  className={cn(errors.lastName && "border-red-500")}
                  {...register("lastName", {
                    required: "Last name is required",
                  })}
                  placeholder="Last Name"
                />
                {errors.lastName && (
                  <p className="text-red-500 text-sm">
                    {errors.lastName.message}
                  </p>
                )}
              </div>
            </div>
            <div>
              <Label htmlFor="businessName">Business Name *</Label>
              <Input
                id="businessName"
                className={cn(errors.businessName && "border-red-500")}
                {...register("businessName", {
                  required: "Business name is required",
                })}
                placeholder="Business Name"
              />
              {errors.businessName && (
                <p className="text-red-500 text-sm">
                  {errors.businessName.message}
                </p>
              )}
            </div>
            <div>
              <Label htmlFor="phoneNumber">Phone Number</Label>
              <div className="flex items-center gap-2">
                <Input
                  id="phoneNumber"
                  {...register("phoneNumber")}
                  placeholder="Phone Number"
                />
              </div>
              {errors.phoneNumber && (
                <p className="text-red-500 text-sm">
                  {errors.phoneNumber.message}
                </p>
              )}
            </div>
          </CardContent>
        </Card>
      </form>
    </div>
  );
};

type PasswordFormData = {
  newPassword: string;
  confirmPassword: string;
};

export const PasswordForm = () => {
  const updatePasswordMutation = useSignedMutation(`/v1/developer/password`, {
    method: "PUT",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<PasswordFormData>();

  const newPassword = watch("newPassword");

  return (
    <div className="max-w-xl mx-auto w-full">
      <form
        onSubmit={handleSubmit((data) =>
          updatePasswordMutation.mutate(
            { data: data.newPassword },
            { onSuccess: () => reset() },
          ),
        )}
        className="space-y-4"
      >
        <h2 className="text-2xl font-semibold">Password</h2>
        {updatePasswordMutation.isSuccess && (
          <Callout type="info">Password updated successfully</Callout>
        )}
        {updatePasswordMutation.isError && (
          <Callout type="danger">
            Unable to update password. Please try again later.
          </Callout>
        )}
        <Card className="overflow-hidden">
          <CardHeader className="border-b bg-muted">
            <div className="flex justify-between items-center w-full">
              <CardTitle>Change Password</CardTitle>
              <ActionButton
                type="submit"
                isPending={updatePasswordMutation.isPending}
              >
                Update Password
              </ActionButton>
            </div>
          </CardHeader>
          <CardContent className="mt-4">
            <div>
              <Label htmlFor="newPassword">New Password</Label>
              <Input
                id="newPassword"
                type="password"
                {...register("newPassword", {
                  required: "New password is required",
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters",
                  },
                })}
                placeholder="New Password"
              />
              {errors.newPassword && (
                <p className="text-red-500 text-sm">
                  {errors.newPassword.message}
                </p>
              )}
            </div>
            <div>
              <Label htmlFor="confirmPassword">Confirm Password</Label>
              <Input
                id="confirmPassword"
                type="password"
                {...register("confirmPassword", {
                  required: "Please confirm your password",
                  validate: (value) =>
                    value === newPassword || "Passwords do not match",
                })}
                placeholder="Confirm Password"
              />
              {errors.confirmPassword && (
                <p className="text-red-500 text-sm">
                  {errors.confirmPassword.message}
                </p>
              )}
            </div>
          </CardContent>
        </Card>
      </form>
    </div>
  );
};

export const ProfilePage = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Page />
    </QueryClientProvider>
  );
};
