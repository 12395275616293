import * as React from "react";
import { SVGProps } from "react";

export const CoreWeatherIcon = ({
  fill = "#F05514",
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={25}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 24"
    {...props}
  >
    <path
      d="M12.5 20.339C7.91 20.339 4.16 16.59 4.16 12S7.91 3.69 12.5 3.69c4.59 0 8.31 3.748 8.31 8.31 0 4.62-3.72 8.339-8.31 8.339Zm12-8.368L21.767 9.5l1.104-3.516-3.603-.784-.784-3.603L14.97 2.73 12.47 0l-2.47 2.731-3.516-1.104-.784 3.603-3.603.785L3.232 9.53.5 12.03l2.732 2.47-1.105 3.516 3.603.784.784 3.603 3.516-1.133L12.529 24l2.469-2.731 3.516 1.104.785-3.604 3.603-.812-1.134-3.517 2.732-2.47Z"
      fill={fill}
    />
    <path
      d="M7.24 6.77a7.436 7.436 0 1 1 10.518 10.518 7.438 7.438 0 0 1-10.519 0C4.334 14.382 4.335 9.646 7.24 6.77Z"
      fill={fill}
    />
  </svg>
);
