import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "zudoku/ui/Card";
import { LoaderIcon } from "lucide-react";
import { useCallback, useEffect } from "react";

export const SuccessCard = ({
  onDone,
  isSuccess,
}: {
  onDone: () => void;
  isSuccess: boolean;
}) => {
  const onDoneCallback = useCallback(onDone, [onDone]);
  useEffect(() => {
    if (!isSuccess) return;
    setTimeout(onDone, 5000);
  }, [onDoneCallback, isSuccess]);

  return (
    <Card className="h-full">
      <CardHeader>
        <CardTitle className="text-center">
          {isSuccess ? "Payment successful!" : "Waiting for payment"}
        </CardTitle>
        <CardDescription></CardDescription>
      </CardHeader>

      <CardContent className="flex gap-2 items-center justify-center text-muted-foreground text-sm font-semibold">
        <LoaderIcon size={16} className="animate-[spin_2s_linear_infinite]" />
        {isSuccess
          ? "Redirecting to Subscriptions & Keys"
          : "Processing payment"}
      </CardContent>
    </Card>
  );
};
