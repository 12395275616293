import {
  ApiIdentity,
  ApiIdentityPlugin,
  ProfileMenuPlugin,
  ZudokuContext,
} from "zudoku";
import { ZUPLO_ENDPOINT } from "./src/config";
import {
  Subscription,
  SUBSCRIPTIONS_PATH,
} from "./src/queries/subscriptionsQuery";
import { UserIcon } from "lucide-react";

export class AccuweatherIdentityPlugin
  implements ApiIdentityPlugin, ProfileMenuPlugin
{
  getProfileMenuItems() {
    return [
      {
        label: "Profile",
        path: "/profile",
        icon: UserIcon,
      },
    ];
  }
  async getIdentities(context: ZudokuContext) {
    const token = await context.authentication?.getAccessToken();

    if (!token) {
      return [];
    }

    const subscriptionsRequest = await fetch(
      ZUPLO_ENDPOINT + SUBSCRIPTIONS_PATH,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    const subscription = (await subscriptionsRequest.json()) as Subscription[];

    return subscription
      .filter((sub) => sub.status === "active")
      .flatMap((subscription) =>
        subscription.apps.map(
          (app) =>
            ({
              label: `${subscription.label} - ${app.label}`,
              id: app.id,
              authorizeRequest: (request: Request) => {
                request.headers.set(
                  "Authorization",
                  `Bearer ${app.keys[0].key}`,
                );
                return request;
              },
            }) satisfies ApiIdentity,
        ),
      );
  }
}
