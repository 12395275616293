import { Callout, Head, useAuth } from "zudoku/components";
import { useShoppingCart } from "../pricing-page/ShoppingCart";
import { Products } from "../pricing-page/Products";
import { ShoppingCartIcon, Trash2Icon } from "lucide-react";
import { useSignedMutation, useSignedQuery } from "../useQuery";
import { cn } from "../components/cn";
import { useState } from "react";
import { useRecurly } from "@recurly/react-recurly";
import { SuccessCard } from "./SuccessCard";
import { ExposedComponentProps } from "zudoku";
import { CurrentBillingInformation } from "./CurrentBillingInformation";
import { Recurly } from "../Recurly";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "../queries/client";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "zudoku/ui/AlertDialog";
import { ActionButton } from "zudoku/ui/ActionButton";
import { CardElement } from "@recurly/react-recurly";
import { CreditCardForm } from "../CreditCardForm";
import { Card, CardDescription, CardHeader, CardTitle } from "zudoku/ui/Card";
import { Label } from "zudoku/ui/Label";

export interface PaymentInformation {
  primary_payment_method: boolean;
  backup_payment_method: boolean;
  first_name: string;
  last_name: string;
  valid: boolean;
  address: {
    street1: string;
    street2: any;
    city: string;
    region: string;
    postal_code: string;
    country: string;
    phone: any;
  };
  payment_method: {
    object: string;
    card_type: string;
    first_six: string;
    last_four: string;
    cc_bin_country: any;
    exp_month: number;
    exp_year: number;
    card_network_preference: any;
  };
  created_at: string;
  updated_at: string;
}

const Page = ({
  onDone,
  upgradeFrom,
}: {
  onDone: () => void;
  upgradeFrom: string;
}) => {
  const shoppingCart = useShoppingCart<string>({ defaultItems: [] });
  const auth = useAuth();

  const subscribeMutation = useSignedMutation("/v1/developer/subscriptions");
  const paymentInformation = useSignedQuery<
    PaymentInformation | { error: { type: string; message: string } }
  >("/v1/developer/payment-information");
  const upgradeMutation = useSignedMutation(
    `/v1/developer/subscriptions/{subscriptionId}/planId`,
    { method: "PUT" },
  );
  const recurly = useRecurly();
  const [error, setError] = useState<string | null>(null);
  const [useUpdatedBilling, setUseUpdatedBilling] = useState(false);

  const handleDone = () => {
    // @NOTE - clear the shopping cart if the transaction was successful
    if (
      (subscribeMutation.isSuccess || upgradeMutation.isSuccess) &&
      shoppingCart.length > 0
    ) {
      shoppingCart.clearCart();
    }
    onDone();
  };

  return (
    <section className="flex flex-col py-12 gap-4 max-w-screen-lg mx-auto">
      <style>
        {`
          /* @NOTE- haven't found a way to import a css file in zudoku*/
          .recurly-element {
            height: 30px !important;
            margin-top: 0px;
            background-color: transparent !important;
            font-family: "Solis";
          }
        `}
      </style>
      {(error || subscribeMutation.isError || upgradeMutation.isError) && (
        <Callout type="caution">
          {error ||
            ((
              subscribeMutation.error !== null &&
              (subscribeMutation.error as { message: string })
            ).message as unknown as string) ||
            (upgradeMutation.error !== null &&
              ((upgradeMutation.error as { message: string })
                .message as string))}
        </Callout>
      )}
      <form
        onSubmit={async (e) => {
          try {
            setError(null);
            e.preventDefault();

            const token = await new Promise<{ id: string; type: string }>(
              (res, rej) => {
                recurly.token(e.currentTarget, (err, token) => {
                  if (err && err.message) {
                    setError(err.message);
                    rej(err);
                  } else {
                    res(token);
                  }
                });
              },
            );

            if (upgradeFrom) {
              if (shoppingCart.length > 1) {
                throw new Error("Cannot upgrade with multiple subscriptions");
              }
              upgradeMutation.mutate({
                data: shoppingCart.items.at(0).value,
                params: { subscriptionId: upgradeFrom },
                headers: {
                  "x-recurly-token": token.id,
                },
              });
            } else {
              subscribeMutation.mutate({
                data: shoppingCart.items.map((item) => ({
                  planId: item.value,
                  token: token.id,
                  email: auth.profile.email,
                  name: auth.profile.name,
                })),
                headers: {
                  "x-recurly-token": token.id,
                },
              });
            }
          } catch (e) {
            const fields =
              e?.fields?.length > 0
                ? " Check the following fields: " + e?.fields?.join(", ")
                : "";
            setError(
              (e.message ? e.message : "An unknown error occurred") + fields,
            );
            // Scroll to the top of the page so the error message is visible
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }
        }}
      >
        <div className="grid grid-cols-1 gap-2 lg:grid-cols-2 mb-4 items-center">
          <div className="flex flex-col gap-1">
            <h1 className="font-extrabold text-start text-2xl m-0">Checkout</h1>
            <p className="text-muted-foreground m-0">
              Your selected subscription
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-[3fr,2fr] w-full gap-8">
          <div>
            <Card className="grid grid-cols-1 w-full">
              {shoppingCart.length === 0 && (
                <div className="p-5 border-b text-sm">No products selected</div>
              )}
              {shoppingCart &&
                shoppingCart.items &&
                shoppingCart.items.map((item, i) => {
                  const product = Products.find((product) =>
                    product.plans.find((plan) => plan.id === item.value),
                  );
                  const plan = Products.flatMap(
                    (product) => product.plans,
                  ).find((plan) => plan.id === item.value);

                  return (
                    <div
                      key={`shoppingCartItem-${i}`}
                      className="p-5 border-b flex gap-2.5 flex-col"
                    >
                      <div className="flex justify-between">
                        <div className="flex items-center gap-2">
                          <product.icon className="h-8 w-8" />
                          <div className="font-semibold text-xl">
                            {product.label}
                          </div>
                        </div>
                        <div className="text-lg font-semibold">
                          {plan.label}
                        </div>
                      </div>
                      <div className="grid grid-cols-[1fr,min-content]">
                        <div>
                          <div className="font-semibold">$250/mo</div>
                          <div className="text-sm text-muted-foreground">
                            $0.15 CPM over 1,800,000 calls per month
                          </div>
                        </div>
                        <div className="flex items-center">
                          <AlertDialog>
                            <AlertDialogTrigger asChild>
                              <button
                                type="button"
                                className="p-3 hover:bg-neutral-100 rounded"
                              >
                                <Trash2Icon size={16} />
                              </button>
                            </AlertDialogTrigger>
                            <AlertDialogContent>
                              <AlertDialogHeader>
                                <AlertDialogTitle>
                                  Are you sure you want to remove this?
                                </AlertDialogTitle>
                                <AlertDialogDescription>
                                  This will remove{" "}
                                  <span className="text-destructive">
                                    {product.label} {plan.label}
                                  </span>{" "}
                                  from checkout
                                </AlertDialogDescription>
                              </AlertDialogHeader>
                              <AlertDialogFooter>
                                <AlertDialogCancel>Cancel</AlertDialogCancel>
                                <AlertDialogAction asChild>
                                  <button
                                    onClick={() =>
                                      shoppingCart.removeFromCart(item.id)
                                    }
                                  >
                                    Continue
                                  </button>
                                </AlertDialogAction>
                              </AlertDialogFooter>
                            </AlertDialogContent>
                          </AlertDialog>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </Card>
          </div>
          <div className="">
            {(subscribeMutation.isPending || subscribeMutation.isSuccess) && (
              <SuccessCard
                onDone={handleDone}
                isSuccess={subscribeMutation.isSuccess}
              />
            )}
            {(upgradeMutation.isPending || upgradeMutation.isSuccess) && (
              <SuccessCard
                onDone={handleDone}
                isSuccess={upgradeMutation.isSuccess}
              />
            )}

            {!subscribeMutation.isPending &&
              !subscribeMutation.isSuccess &&
              !upgradeMutation.isPending &&
              !upgradeMutation.isSuccess &&
              (!useUpdatedBilling && "first_name" in paymentInformation.data ? (
                <CurrentBillingInformation
                  onRequestUpdate={() => setUseUpdatedBilling(true)}
                  name={`${paymentInformation.data.first_name} ${paymentInformation.data.last_name}`}
                  number={
                    "**** ".repeat(3) +
                    paymentInformation.data.payment_method?.last_four
                  }
                >
                  <div
                    className={cn(
                      "flex flex-col max-w-md gap-2 mt-7",
                      (subscribeMutation.isSuccess ||
                        upgradeMutation.isSuccess) &&
                        "hidden",
                    )}
                  >
                    <ActionButton
                      isPending={
                        subscribeMutation.isPending || upgradeMutation.isPending
                      }
                      type="submit"
                      className="w-full"
                      disabled={
                        shoppingCart.length === 0 ||
                        subscribeMutation.isPending ||
                        upgradeMutation.isPending
                      }
                    >
                      <div className="flex gap-2 items-center">
                        ({shoppingCart.length}) Checkout
                        <ShoppingCartIcon size={16} />
                      </div>
                    </ActionButton>
                  </div>
                </CurrentBillingInformation>
              ) : (
                <Card className="w-full max-w-md pb-6">
                  <CardHeader className="border-b mb-3">
                    <CardTitle>Billing Information</CardTitle>
                    <CardDescription>
                      Your billing address and payment information
                    </CardDescription>
                  </CardHeader>
                  <div className="space-y-2 px-6 pb-6">
                    <Label htmlFor="cardNumber">Credit Card</Label>
                    <CardElement className="h-8 p-0 m-0 box-border [&>*]:p-0 [&>*]:m-0 [&>*]:box-border" />
                  </div>
                  <CreditCardForm
                    defaultValues={
                      "first_name" in paymentInformation.data
                        ? {
                            country: paymentInformation.data.address.country,
                            address: paymentInformation.data.address.street1,
                            firstName: paymentInformation.data.first_name,
                            lastName: paymentInformation.data.last_name,
                            city: paymentInformation.data.address.city,
                            postalCode:
                              paymentInformation.data.address.postal_code,
                          }
                        : {}
                    }
                  />
                  <div
                    className={cn(
                      "flex flex-col max-w-md gap-2 mt-8 px-6",
                      subscribeMutation.isSuccess && "hidden",
                    )}
                  >
                    <ActionButton
                      isPending={subscribeMutation.isPending}
                      type="submit"
                      className="w-full"
                      disabled={
                        shoppingCart.length === 0 || subscribeMutation.isPending
                      }
                    >
                      <div className="flex gap-2 items-center">
                        ({shoppingCart.length}) Checkout
                        <ShoppingCartIcon size={16} />
                      </div>
                    </ActionButton>
                  </div>
                </Card>
              ))}
          </div>
        </div>
      </form>
    </section>
  );
};

export const CheckoutPage = ({
  navigate,
  searchParams,
}: ExposedComponentProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Head>
        <title>Checkout</title>
      </Head>
      <Recurly>
        <Page
          onDone={() => navigate("/subscriptions")}
          upgradeFrom={searchParams.get("from")}
        />
      </Recurly>
    </QueryClientProvider>
  );
};
