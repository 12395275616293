import { cn } from "../components/cn";
import { CheckIcon } from "lucide-react";
import React from "react";
import { Product } from "../pricing-page/Products";

export const ProductFeatures = ({ product }: { product: Product }) => {
  return (
    <>
      {product.features.map((feature) => (
        <div
          key={feature}
          className="text-black dark:text-white text-sm grid grid-cols-subgrid col-span-full px-1 border-t hover:bg-border/10 :bg-border/20 transition-all"
        >
          <div className="font-medium flex items-center px-2">{feature}</div>
          {product.plans.map((plan) => (
            <div
              key={plan.id}
              className={cn(
                "border-l flex items-center justify-center min-h-14",
              )}
            >
              {plan.features[feature] === true ? (
                <CheckIcon size={18} strokeWidth={2.5} />
              ) : (
                plan.features[feature]
              )}
            </div>
          ))}
        </div>
      ))}
    </>
  );
};
