export const SUBSCRIPTIONS_PATH = "/v1/developer/subscriptions";

export type Key = {
  id?: string;
  key: string;
  createdOn: string;
  expiresOn: string;
};

export type Subscription = {
  id: string;
  ownerSub: string;
  planId: string;
  label: string;
  manageable: boolean;
  usage: {
    used: number;
    budget: number;
  };
  startDate: string;
  endDate?: string;
  status: "active" | "canceled" | "expired";
  apps: {
    id: string;
    label: string;
    description: string;
    keys: Key[];
  }[];
};
