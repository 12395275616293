import { useState } from "react";
import { Label } from "zudoku/ui/Label";
import { Input } from "zudoku/ui/Input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "zudoku/ui/Select";
import countries from "./countries.json";
import { CreditCardDefaultValues } from "./subscriptions-page/UpdateBillingInformation";

export const CreditCardForm = ({
  defaultValues,
}: {
  defaultValues?: CreditCardDefaultValues;
}) => {
  const [country, setCountry] = useState(defaultValues?.country ?? "US");

  return (
    <div className="[&_.recurly-element]:rounded border-border">
      <div className="px-6 border-t pt-4 flex flex-col gap-2">
        <div className="space-y-1">
          <Label htmlFor="firstName">First Name</Label>
          <Input
            id="firstName"
            data-recurly="first_name"
            placeholder="John"
            defaultValue={defaultValues?.firstName}
            name="firstName"
            required
          />
        </div>
        <div className="space-y-1">
          <Label htmlFor="lastName">Last Name</Label>
          <Input
            id="lastName"
            data-recurly="last_name"
            defaultValue={defaultValues?.lastName}
            placeholder="Doe"
            name="lastName"
            required
          />
        </div>
        <div className="space-y-1">
          <Label htmlFor="address">Address</Label>
          <Input
            id="address"
            defaultValue={defaultValues?.address}
            data-recurly="address1"
            placeholder="1234 Elm St"
            name="address"
            required
          />
        </div>
        <div className="grid grid-cols-2 gap-2">
          <div className="space-y-1 col-span-2">
            <Label htmlFor="city">City</Label>
            <Input
              id="city"
              placeholder="Plano"
              data-recurly="city"
              defaultValue={defaultValues?.city}
              name="city"
              required
            />
          </div>
          <div className="space-y-1">
            <Label htmlFor="postalCode">ZIP</Label>
            <Input
              id="postalCode"
              data-recurly="postal_code"
              defaultValue={defaultValues?.postalCode}
              placeholder="12345"
              name="postalCode"
              required
            />
          </div>
          <div className="space-y-1">
            <Label htmlFor="state">State</Label>
            <Input
              id="state"
              data-recurly="state"
              placeholder="PA"
              defaultValue={defaultValues?.state}
              name="state"
              required
            />
          </div>
          <div className="space-y-1 col-span-2">
            <Label htmlFor="country">Country</Label>
            <input
              id="country"
              name="country"
              type="hidden"
              data-recurly="country"
              value={country}
            />
            <Select
              value={country}
              onValueChange={(value) => {
                setCountry(value);
              }}
            >
              <SelectTrigger className="w-full">
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {countries.map((country) => (
                  <SelectItem key={country["alpha2"]} value={country["alpha2"]}>
                    {country.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>
      </div>
    </div>
  );
};
