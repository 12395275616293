import { Head } from "zudoku/components";
import { queryClient } from "../../queries/client";
import { cn } from "../../components/cn";
import { Button } from "zudoku/ui/Button";
import React, { useState } from "react";
import { Product, Products } from "../../pricing-page/Products";
import { ExposedComponentProps } from "zudoku";
import { useSignedMutation, useSignedQuery } from "../../useQuery";
import {
  Subscription,
  SUBSCRIPTIONS_PATH,
} from "../../queries/subscriptionsQuery";
import { QueryClientProvider } from "@tanstack/react-query";
import { ProductFeatures } from "../ProductFeatures";
import { useShoppingCart } from "../../pricing-page/ShoppingCart";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "zudoku/ui/AlertDialog";
import { ActionButton } from "zudoku/ui/ActionButton";
import { Input } from "zudoku/ui/Input";

const ProductCard = ({
  product,
  currentPlanId,
  subscriptionId,
  navigate,
  disallowedPlanIds = [],
}: {
  navigate: (to: string) => void;
  product: Product;
  currentPlanId: string;
  disallowedPlanIds?: string[];
  subscriptionId: string;
}) => {
  const cart = useShoppingCart({ defaultItems: [] });
  const [confirmInput, setConfirmInput] = useState("");

  const cancelSubscriptionMutation = useSignedMutation(
    `/v1/developer/subscriptions/${subscriptionId}/status`,
    { method: "PUT" },
    { onSuccess: () => navigate("/subscriptions") },
  );

  const currentPlanIndex = product.plans.findIndex(
    (plan) => plan.id === currentPlanId,
  );

  return (
    <div className="flex flex-col gap-4" id={product.label}>
      <div
        className={cn(
          "grid grid-cols-[280px_1fr] border border-border rounded-lg shadow",
          product.plans.length === 4 && `grid-cols-5`,
          product.plans.length === 3 && `grid-cols-4`,
          product.plans.length === 2 && `grid-cols-3`,
          product.plans.length === 1 && `grid-cols-2`,
        )}
      >
        <div className="grid grid-rows-[1fr_min-content] grid-cols-subgrid col-span-full">
          <div>
            <div className="flex gap-2 text-lg p-4 font-semibold items-center">
              <product.icon className="not-prose h-10" />
              {product.label}
            </div>
          </div>
          {product.plans.map((plan, index) => (
            <div
              className={cn("border-l flex flex-col gap-1 p-4")}
              key={plan.id}
            >
              <div className="w-full flex justify-between">
                <div className="text-xl font-semibold">{plan.label}</div>
              </div>
              <span className="text-sm text-muted-foreground">{plan.note}</span>
              <span className="font-bold">
                {plan.price}
                {plan.pricePer && <span className="">/{plan.pricePer}</span>}
              </span>
              <AlertDialog>
                <div className="flex flex-col h-full pt-3">
                  <AlertDialogTrigger asChild>
                    <ActionButton
                      isPending={
                        cancelSubscriptionMutation.isPending &&
                        plan.id === currentPlanId
                      }
                      variant={
                        plan.id === currentPlanId ? "destructive" : "secondary"
                      }
                      disabled={
                        (plan.id !== currentPlanId &&
                          disallowedPlanIds.includes(plan.id)) ||
                        !!plan.link ||
                        cancelSubscriptionMutation.isPending
                      }
                      className={cn("w-full my-2 mt-auto")}
                    >
                      {plan.id === currentPlanId
                        ? "Cancel"
                        : disallowedPlanIds.includes(plan.id)
                          ? "Already owned"
                          : currentPlanIndex < index
                            ? "Upgrade"
                            : "Downgrade"}
                    </ActionButton>
                  </AlertDialogTrigger>
                </div>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>
                      {plan.id === currentPlanId
                        ? "Are you sure you want to cancel your subscription?"
                        : "Are you sure you want to change your plan?"}
                    </AlertDialogTitle>
                    <AlertDialogDescription>
                      {plan.id === currentPlanId
                        ? `You will lose access to the current plan (${plan.label}) and all its features. You can always resubscribe later.`
                        : "The new plan will take effect immediately, the existing plan will be canceled. Your Apps and API Keys will be migrated to the new tier."}

                      {plan.id === currentPlanId && (
                        <Input
                          placeholder={plan.label as string}
                          onChange={(e) => setConfirmInput(e.target.value)}
                          value={confirmInput}
                          className="w-full mt-4"
                          type="text"
                        />
                      )}
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction asChild>
                      <button
                        disabled={
                          plan.id === currentPlanId
                            ? plan.label !== confirmInput
                            : false
                        }
                        onClick={() => {
                          if (plan.id === currentPlanId) {
                            cancelSubscriptionMutation.mutate({
                              data: "canceled",
                            });
                          }
                          if (plan.id !== currentPlanId) {
                            cart.clearCart([{ id: plan.id, value: plan.id }]);
                            navigate(`/checkout?from=${subscriptionId}`);
                          }
                        }}
                      >
                        Continue
                      </button>
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            </div>
          ))}
        </div>

        <ProductFeatures product={product} />
      </div>
    </div>
  );
};

const Page = ({ params, navigate }: ExposedComponentProps) => {
  const subscriptionsQuery = useSignedQuery<Subscription[]>(SUBSCRIPTIONS_PATH);
  if (!params.subscription) {
    throw new Error("Subscription not found");
  }

  const subscription = subscriptionsQuery.data.find(
    (subscription) => subscription.id === params.subscription,
  );

  if (!subscription) {
    throw new Error("Subscription not found");
  }

  const product = Products.find((product) =>
    product.plans.find((plan) => plan.id === subscription.planId),
  );

  if (!product) {
    throw new Error("Product not found");
  }

  return (
    <section className="flex flex-col py-12 gap-10 max-w-screen-xl mx-auto not-prose">
      <Head>
        <title>Update Subscription</title>
      </Head>
      <div className="flex flex-col items-center justify-between gap-6">
        <div className="flex justify-between w-full">
          <h2 className="font-semibold text-2xl">Update Subscription</h2>
          <Button
            variant="outline"
            className="min-w-36"
            onClick={() => navigate("/subscriptions")}
          >
            Back
          </Button>
        </div>
        <ProductCard
          product={product}
          currentPlanId={subscription.planId}
          subscriptionId={subscription.id}
          navigate={navigate}
          disallowedPlanIds={subscriptionsQuery.data.map((sub) => {
            if (sub.status === "active") {
              return sub.planId;
            }
          })}
        />
      </div>
    </section>
  );
};

export const ChangeSubscriptionPage = (props: ExposedComponentProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Page {...props} />
    </QueryClientProvider>
  );
};
