import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "zudoku/ui/Dialog";
import { CreditCardIcon } from "lucide-react";
import { Button } from "zudoku/ui/Button";
import { useState } from "react";
import { useSignedMutation, useSignedQuery } from "../useQuery";
import { PaymentInformation } from "../checkout-page/CheckoutPage";
import { Callout } from "zudoku/components";
import { CreditCardForm } from "../CreditCardForm";
import { ActionButton } from "zudoku/ui/ActionButton";
import { Label } from "zudoku/ui/Label";
import { useRecurly } from "@recurly/react-recurly";
import { CardElement } from "@recurly/react-recurly";

export type CreditCardDefaultValues = {
  firstName?: string;
  lastName?: string;
  address?: string;
  city?: string;
  postalCode?: string;
  state?: string;
  country?: string;
};

export const UpdateBillingInformation = () => {
  const [error, setError] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const recurly = useRecurly();

  const paymentInformation = useSignedQuery<PaymentInformation>(
    "/v1/developer/payment-information",
  );
  const updateBillingMutation = useSignedMutation(
    "/v1/developer/payment-information",
    {
      method: "PUT",
    },
    {
      onSuccess: () => setOpen(false),
    },
  );

  return (
    <Dialog open={open} onOpenChange={(newOpen) => setOpen(newOpen)}>
      <style>
        {`
          /* @NOTE- haven't found a way to import a css file in zudoku*/
          .recurly-element, .recurly-hosted-field {
            height: 30px !important;
            margin-top: 0px;
            background-color: transparent !important;
            font-family: "Solis";
          }
        `}
      </style>
      <DialogTrigger asChild>
        <ActionButton
          variant="outline"
          type="button"
          isPending={updateBillingMutation.isPending}
          className="flex"
        >
          <div className="flex items-center gap-2">
            <CreditCardIcon size={16} />
            Update Billing Information
          </div>
        </ActionButton>
      </DialogTrigger>
      <DialogContent className="p-0 gap-0">
        <form
          onSubmit={async (e) => {
            e.stopPropagation();
            e.preventDefault();
            setError(null);
            try {
              // Clone the FormData from e.currentTarget
              const formData = new FormData(e.currentTarget);

              // Update credit card cvv on recurly
              const token = await new Promise<{ id: string; type: string }>(
                (res, rej) => {
                  recurly.token(e.currentTarget, (err, token) => {
                    if (err && err.message) {
                      setError(err.message);
                      rej(err);
                    } else {
                      res(token);
                    }
                  });
                },
              );

              // Update billing address
              const data = {
                firstName: formData.get("firstName"),
                lastName: formData.get("lastName"),
                address: formData.get("address"),
                city: formData.get("city"),
                postalCode: formData.get("postalCode"),
                state: formData.get("state"),
                country: formData.get("country"),
              };

              updateBillingMutation.mutate({
                data,
                headers: {
                  "x-recurly-token": token.id,
                },
              });
            } catch (e) {
              const fieldsMessage =
                e?.fields?.length > 0
                  ? " Check the following fields: " + e?.fields?.join(", ")
                  : "";
              setError(
                (e.message ? e.message : "An unknown error occurred") +
                  fieldsMessage,
              );
            }
          }}
        >
          <DialogHeader className="p-6 pb-0">
            <DialogTitle>New Billing Information</DialogTitle>
            <DialogDescription>
              Your billing address and payment information
            </DialogDescription>
          </DialogHeader>

          <div className="px-6 py-1 flex flex-col gap-2">
            {(error || updateBillingMutation.isError) && (
              <Callout type="caution">
                {error ||
                  (updateBillingMutation.error !== null &&
                    (updateBillingMutation.error as { message: string })
                      .message)}
              </Callout>
            )}
          </div>
          <div className="px-6 py-4">
            <div className="space-y-2">
              <div className="space-y-2">
                <Label htmlFor="cardNumber">Credit Card</Label>
                <CardElement className="h-8 p-0 m-0 box-border [&>*]:p-0 [&>*]:m-0 [&>*]:box-border " />
              </div>
            </div>
          </div>
          <CreditCardForm
            defaultValues={{
              postalCode: paymentInformation.data?.address?.postal_code,
              city: paymentInformation.data?.address?.city,
              state: paymentInformation.data?.address?.region,
              address: paymentInformation.data?.address?.street1,
              country: paymentInformation.data?.address?.country,
              firstName: paymentInformation.data?.first_name,
              lastName: paymentInformation.data?.last_name,
            }}
          />

          <DialogFooter className="p-6 flex sm:justify-between w-full gap-2">
            <DialogClose asChild>
              <Button type="submit" variant="outline" size="sm">
                Cancel
              </Button>
            </DialogClose>
            <Button type="submit" variant="secondary" size="sm">
              Update
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};
