import { ExposedComponentProps } from "zudoku";
import { ClientOnly, Head } from "zudoku/components";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./queries/client";
import { Elements, RecurlyProvider } from "@recurly/react-recurly";
import { ReactNode, useEffect, useState } from "react";

const RECURLY_PUBLIC_KEY = "ewr1-hdecgYLYwzRUOz7BwgJwVF";

const useRecurlyLoader = () => {
  const [recurlyReady, setRecurlyReady] = useState(false);
  useEffect(() => {
    const configure = (retry = 0) => {
      setTimeout(() => {
        if (retry > 5) {
          return;
        }
        if ("recurly" in window) {
          window.recurly.configure(RECURLY_PUBLIC_KEY);
          setRecurlyReady(true);
          return;
        } else {
          configure(retry + 1);
        }
      }, 200);
    };

    configure();
  }, []);

  return {
    recurlyReady,
  };
};

export const Recurly = ({ children }: { children: ReactNode }) => {
  const { recurlyReady } = useRecurlyLoader();

  return (
    <>
      <Head>
        <script src="https://js.recurly.com/v4/recurly.js"></script>
        <link
          href="https://js.recurly.com/v4/recurly.css"
          rel="stylesheet"
          type="text/css"
        />
      </Head>
      {recurlyReady && (
        <RecurlyProvider publicKey={RECURLY_PUBLIC_KEY}>
          <Elements>{children}</Elements>
        </RecurlyProvider>
      )}
    </>
  );
};
