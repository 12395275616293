import { useZudoku } from "zudoku/components";
import {
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQueryClient,
  useSuspenseQuery,
  UseSuspenseQueryOptions,
} from "@tanstack/react-query";
import { ZUPLO_ENDPOINT } from "./config";

export const useSignedQuery = <
  TData = any,
  TError = unknown,
  TQueryKey extends QueryKey = QueryKey
>(
  path: string,
  options: Omit<
    UseSuspenseQueryOptions<TData, TError, TData, TQueryKey>,
    "queryKey" | "queryFn"
  > = {}
) => {
  const zudoku = useZudoku();
  return useSuspenseQuery<TData, TError, TData, TQueryKey>({
    queryKey: [path] as unknown as TQueryKey,
    queryFn: async () => {
      const signedRequest = await zudoku.signRequest(
        new Request(ZUPLO_ENDPOINT + path)
      );
      const response = await fetch(signedRequest);

      // Check for non-200 status codes and throw an error
      if (!response.ok) {
        const res = await response.clone().json();

        // Check if the response matches the httpproblem format
        if (res.type && res.title && res.status) {
          const message = res.detail ?? `${res.title} (status: ${res.status})`;

          const error = new Error(message);
          (error as any).response = response;
          throw error;
        }

        const error = new Error(`HTTP error! status: ${response.status}`);
        (error as any).response = response;
        throw error;
      }

      return response.clone().json();
    },
    ...options,
  });
};

type MutationArgs = {
  headers?: Record<string, string>;
  params?: Record<string, string>;
  data?: any;
};

export const useSignedMutation = <
  TData = any,
  TError = unknown,
  TVariables = MutationArgs,
  TContext = unknown
>(
  path: string,
  { method = "POST" }: { method: string } = {
    method: "POST",
  },
  options: UseMutationOptions<TData, TError, TVariables, TContext> = {}
) => {
  const zudoku = useZudoku();
  const queryClient = useQueryClient();

  return useMutation<TData, TError, TVariables, TContext>({
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries();
      options.onSuccess?.(...args);
    },
    mutationFn: async (args?: {
      headers?: Record<string, string>;
      params?: Record<string, string>;
      data?: any;
    }) => {
      const finalPath = Object.entries(args.params ?? {}).reduce(
        (acc, [key, value]) => acc.replace(`{${key}}`, value),
        path
      );
      const response = await fetch(
        await zudoku.signRequest(
          new Request(ZUPLO_ENDPOINT + finalPath, {
            method,
            body: JSON.stringify(args.data),
            headers: {
              ...args.headers,
              "Content-Type": "application/json",
            },
          })
        )
      );

      // Check for non-200 status codes and throw an error
      if (!response.ok) {
        const res = await response.clone().json();

        // Check if the response matches the httpproblem format
        if (res.type && res.title && res.status) {
          const message = res.detail ?? `${res.title} (status: ${res.status})`;

          const error = new Error(message);
          (error as any).response = response;
          throw error;
        }

        const error = new Error(`HTTP error! status: ${response.status}`);
        (error as any).response = response;
        throw error;
      }

      // @NOTE - some endpoints will return 204s which contain no data
      if (response.status === 204) {
        return {};
      }

      return response.clone().json();
    },
  });
};
